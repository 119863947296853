import React, { useState } from 'react';
import { getImageUrl } from 'takeshape-routing';

import Link from './link';
import folx from '../assets/images/coffeeFolx.png';

const FundPageIntro = ({ className = '' }) => (
  <section className={`fundPageIntro f x ${className}`}>
    <div className="mastheadCopy f x fdc">
      <h1>
        The
        <br />
        ComeTogether Cafe Fund
      </h1>
      <p className="h3">With your help, we've raised over $20,000</p>
      <p>
        Your favorite cafes and coffee roasters need our help. As we keep our distance to save lives, let’s Come Together to save livelihoods.
      </p>
      <Link
        to="https://go.rallyup.com/cometogethercoffee"
        className="btn btn--lg btn--ghost ass mt15"
      >
        Donate Now
      </Link>
    </div>
    <div className="mastheadAsset">
      <img className="x" alt="The ComeTogether Fund" src={folx} />
    </div>
  </section>
);

export default FundPageIntro;
