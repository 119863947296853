import React, { useState } from 'react';
import { getImageUrl } from 'takeshape-routing';
import slugify from 'slugify';

import routes from '../routes';

import Link from './link';


const CafeCard = ({ cafe }) => {
  const cafeSlug = slugify(cafe.name.toLowerCase());
  let ctaCopy = 'Support';
  let cafeName = cafe.name;
  if (cafe.shortName !== null) {
    cafeName = cafe.shortName;
  }

  return (
    //
    <article className="card cafe" id={cafeSlug}>
      {cafe.coverImage !== null ? (
        <img
          className="card__image"
          src={getImageUrl(cafe.coverImage.path, {
            h: 400,
            w: 600,
            fit: 'crop',
          })}
          alt={cafe.name}
        />
      ) : (
        <img
          className="card__image"
          src="https://placekitten.com/600/400"
          alt={cafe.name}
        />
      )}
      <div className="card__wrapper">
        <div className="card__copy">
          <div className="cafe__nameLocation">
            <h2 className="cafe__name">{cafe.name}</h2>
            {cafe.shortLocation && (
              <h3 className="cafe__shortLocation h3--secondary">{cafe.shortLocation}</h3>
            )}
            <hr className="blueHr" />
          </div>
          {cafe.cardBio && (
            <div className="card__body">
              <p>{cafe.cardBio}</p>
            </div>
          )}
        </div>
        <Link
          to={routes.cafe(cafe.name)}
          className="card__cta"
        >
          More
          {' '}
          {cafeName}
          {' '}
          →
        </Link>
      </div>
      <Link to={routes.cafe(cafe.name)} className="cafe abs fill" />
    </article>
  );
};

export default CafeCard;
