import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Link from './link';
import CafeCard from './CafeCard';

import routes from '../routes';

function shuffleArray(array) {
  const a = array.slice();
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

const MoreCafes = ({
  cafes,
  size = 6,
  desktopLimit = 3,
  shuffle = true,
  noArray = false,
  headerCopy = 'More Cafes',
  ctaCopy = 'See More Cafes',
  ctaUrl = '/cafes',
  className = '',
}) => {
  let cafeArray = cafes.slice(0, size);
  if (shuffle) {
    const shuffled = shuffleArray(cafes);
    cafeArray = shuffled.slice(0, size);
  }
  if (noArray) {
    cafeArray = cafes;
  }
  return (
    <section className={`moreCafes ${className}`}>
      <h2>{ headerCopy }</h2>
      <div className={`card-list limit-${desktopLimit}`}>
        {cafeArray.map(cafe => <CafeCard cafe={cafe} key={cafe.name} />)}
      </div>
      <Link
        to={ctaUrl}
        className="btn btn--lg"
      >
        {ctaCopy}
      </Link>
    </section>
  );
};

MoreCafes.propTypes = {
  cafes: PropTypes.arrayOf(PropTypes.object).isRequired,
  size: PropTypes.number,
  headerCopy: PropTypes.string,
  ctaCopy: PropTypes.string,
  ctaUrl: PropTypes.string,
};

MoreCafes.defaultProps = {
  size: 6,
  headerCopy: 'More Cafes',
  ctaCopy: 'See More Cafes',
};

export default MoreCafes;
