import React, { useState } from 'react';
import CafeCard from './CafeCard';

// function shuffleArray(array) {
//   for (let i = array.length - 1; i > 0; i--) {
//     const j = Math.floor(Math.random() * (i + 1));
//     [array[i], array[j]] = [array[j], array[i]];
//   }
// }

const CafeList = ({ cafes, searchQuery }) => {
  const query = searchQuery.toLowerCase();
  const queryLength = query.split('');
  const filteredData = cafes.items.filter(cafe => (
    cafe.name.toLowerCase().includes(query)
      || cafe.location?.toLowerCase().includes(query)
      || cafe.shortLocation?.toLowerCase().includes(query)
      || cafe.searchTerms?.toLowerCase().includes(query)
  ));

  const searchAttempted = filteredData && queryLength.length !== 0;
  const searchSucceeded = searchAttempted && filteredData.length > 0;
  const searchFailed = filteredData.length === 0 && queryLength.length !== 0;

  const searchCafes = searchAttempted ? filteredData : cafes.items;
  // shuffle results so we don't favor people just added and disadvantage those
  // shuffleArray(searchCafes);
  return (
    <>
      <header>
        <h1>
          Cafes
          {searchAttempted && (
            <span className="cafe-list__resultsMsg">
              &nbsp;| Results for {searchQuery}
            </span>
          )}
        </h1>
      </header>
      <section className="card-list">
        {searchCafes.map((cafe, i) => <CafeCard cafe={cafe} key={cafe.name} />)}
      </section>
      {searchFailed && (
        <div className="cafe-list__resultsMsg f fdc jcc aic pb3rem">
          <h2>:(</h2>
          <h2>
            Sorry, we don't have any cafes matching "
            {searchQuery}
            "
          </h2>
          <hr className="wavyHr" />
        </div>
      )}
    </>
  );
};

export default CafeList;
