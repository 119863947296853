import React, { useState } from 'react';
import { graphql } from 'gatsby';
import CafeList from '../components/CafeList';

import Layout from '../layouts/default';
import Link from '../components/link';
import FundPageIntro from '../components/FundPageIntro';
import MoreCafes from '../components/MoreCafes';

const FundPage = ({ data }) => (
  <Layout>
    <FundPageIntro />
    <section className="mb3">
      <div className="copy">
        <h2>What is The ComeTogether Cafe Fund?</h2>
        <p>
          Listening to cafes and baristas around the country, it’s clear the financial deficit due to the COVID-19 pandemic is greater than originally anticipated. From updating health and safety protocols, decreasing hours and staffing, to complete closures, the pandemic has put a stress on coffee businesses greater than anyone could have anticipated. Moreover, the impact has been even greater for small businesses owned by minorities and marginalized individuals.
        </p>
        <p>
          ComeTogether.coffee is now a place to donate money specifically to coffee businesses who need it most.
        </p>
      </div>
    </section>

    <section className="mb3">
      <h2>How do you donate?</h2>
      <p>
        We need your help to make sure the specialty coffee industry we all love can survive this pandemic! Big or small, every donation makes a difference. Please click “Donate Now” to head to our RallyUp page.
      </p>
      <Link
        to="https://go.rallyup.com/cometogethercoffee/ADFAEH"
        className="btn"
      >
        Donate Now
      </Link>
    </section>

    <section className="mb3">
      <h2>How do you apply for financial support?</h2>
      <p>
        ComeTogether.coffee focuses on supporting small specialty coffee cafes and businesses, with 100% of the fund reserved for those who self-identify as being a member of an underrepresented or marginalized group in the coffee industry including but not limited to: BIPOC, disabled, LGBTQIA+, womxn, and racial and ethnic minorities. 50% of the fund is reserved for Black-owned/co-owned businesses. Additionally, ComeTogether.coffee seeks cafes/roasters not only owned by these groups but who also hire diversely.
      </p>
      <p>Cafes and coffee businesses in need that fit this funds criteria, please apply. Applications for the second cohort close at Midnight PST 12/31/20.</p>
      <Link
        to="https://cometogethercoffee.typeform.com/to/yedHqYcL"
        className="btn"
        target="_blank"
      >
        Apply Here
      </Link>
    </section>
    <section className="mb3">
      <h2>Want to donate to individuals or apply for individual aid?</h2>
      <p>
        We have a fund for you! While ComeTogether.coffee’s funds are specific to businesses, Go Fund Bean supports, uplifts, and defends the hourly coffee professional. All individuals looking for support are encouraged to head to
        {' '}
        <Link
          to="https://gofundbean.org"
          target="_blank"
        >
          GoFundBean.org
        </Link>
        .
        If you’re looking to directly support the hourly workers that make coffee possible, please donate via our partners at
        {' '}
        <Link
          to="https://gofundbean.org"
          target="_blank"
        >
          Go Fund Bean.
        </Link>
      </p>
      <Link
        to="https://gofundbean.org"
        className="btn"
        target="_blank"
      >
        Check out GFB
      </Link>
    </section>
    <MoreCafes
      cafes={data.takeshape.getCohorts.cafes}
      desktopLimit={5}
      shuffle={false}
      headerCopy={'Meet Cohort One!'}
      ctaCopy={'Meet the Cohort'}
      ctaUrl={'/blog/meet-cohort-1-our-first-five-fund-recipients'}
    />
  </Layout>
);

export default FundPage;
export const query = graphql`
  query {
    takeshape {
      getCohorts(_id: "6aaf3d63-b8d8-48bd-9df9-6bfcf5b40bf7") {
        cohortTitle
        cafes: cohortCafes {
          name
          cafeUrl
          cardBio
          coverImage {
            path
          }
          location
          shortLocation
          shortName
          status: statusHtml
          howToHelp: howToHelpHtml
          sellingCoffee
          searchTerms
        }
      }
    }
  }
`;
